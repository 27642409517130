import { useCallback, useEffect, useRef, useState } from 'react'
const useFetch = (
  asyncFuntion,
  args = Array<any>(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorCallback = (error) => {},
  immediate = true
): { response: any; error: any; execute: any; loading: boolean } => {
  const isFirstUpdate = useRef(true)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(immediate)

  if (error && errorCallback) {
    errorCallback(error)
  }

  const execute = useCallback(
    (args) => {
      setLoading(true)
      setResponse(null)
      setError(null)
      return asyncFuntion(...args)
        .then(({ data }) => {
          setResponse(data)
        })
        .catch((error) => {
          setError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [asyncFuntion]
  )

  useEffect(() => {
    if (immediate) {
      execute(args)
    }
    // eslint-disable-next-line
  }, [...args])

  useEffect(() => {
    isFirstUpdate.current = false
  }, [])

  return { execute, response, error, loading }
}

export default useFetch
