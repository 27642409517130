import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Card, Stack, Link, Container, Typography } from '@mui/material'
// routes
import { PATH_AUTH } from '../../routes/paths'
// hooks
import useResponsive from '../../hooks/useResponsive'
// components
import Page from '../../components/Page'
import Logo from '../../components/Logo'
import Image from '../../components/Image'
// sections
import { LoginForm } from '../../sections/auth/login'

import illustrationLogin from '../../assets/images/illustration_login.png'
import useLocales from '../../hooks/useLocales'
import useApiHealth from '../../hooks/useMaintenance'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }
})

const HeaderStyle = styled('header')(({ theme }) => {
  return {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7),
    },
  }
})

const SectionStyle = styled(Card)(({ theme }) => {
  return {
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
  }
})

const ContentStyle = styled('div')(({ theme }) => {
  return {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
  }
})

// ----------------------------------------------------------------------

export default function Login() {
  const { translate: t } = useLocales()
  const { loading, renderHealth } = useApiHealth()

  const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')

  return (
    <Page title={t('pages.login')}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {t`auth.register_message`}{' '}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
                data-testid="loginForm-goSignin-button"
              >
                {t`auth.sign_in`}
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t`auth.welcome`}
            </Typography>
            <Image src={illustrationLogin} alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t`auth.connection`}
                </Typography>
              </Box>
            </Stack>
            {!loading && renderHealth()}

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {t`auth.sign_in`}{' '}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                  data-testid="loginForm-goSignin-button"
                >
                  {t`auth.sign_in`}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}
