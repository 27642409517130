import { useState, useEffect, useCallback } from 'react'
// @mui
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  FormControl,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  CardHeader,
} from '@mui/material'
import { Clear, Download, Search } from '@mui/icons-material'
// components
import Page from '../../../components/Page'
import DataListHead from '../../../components/DataListHead'
import Scrollbar from '../../../components/Scrollbar'
// @type
import { DocumentPDF } from '../../../@types/pdf'
// utils
import axiosInstance from '../../../utils/axios'
import { fDate } from '../../../utils/formatTime'
import { documentTypes } from '../../../utils/document'
import { getPathDocument } from '../../../utils/file'
// hooks
import useLocales from '../../../hooks/useLocales'
import { getUriToRedirect } from '../../../utils/downloadFile'
import FlagIcon from '@mui/icons-material/Flag'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import useResponsive from '../../../hooks/useResponsive'
import TuneIcon from '@mui/icons-material/Tune'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
// ----------------------------------------------------------------------

type TableHead = {
  id: string
  label: string
  alignRight?: boolean
  sx?: any
  render?: ((o: any, t: Function) => any) | ((o: any) => any)| ((o: any, t:Function, i:boolean | undefined) => any)
}

const TABLE_HEAD : TableHead[] = [
  {
    id: 'exists',
    sx: { display: { xs: 'none', md: 'table-cell' } },
    label: '',
    render: (document, t) => (
      <Tooltip title={t(`document.list.exists.${document.exists ? 'yes' : 'not_yet'}`) || ''}>
        <FlagIcon color={document.exists ? 'success' : 'warning'} />
      </Tooltip>
    ),
  },
  {
    id: 'quote_id',
    label: 'n° du devis',
    alignRight: false,
    sx: { display: { xs: 'none', md: 'table-cell' } },
    render: (document) => document.quote_id,
  },
  {
    id: 'client_name',
    label: 'Nom client',
    alignRight: false,
    render: (document) => document.client_name,
  },
  {
    id: 'type',
    label: 'Type de document',
    alignRight: false,
    render: (document, t) => t(`document.pdf.${document.type}`),
  },
  {
    id: 'issue_date',
    label: 'Créé le',
    alignRight: false,
    sx: { display: { xs: 'none', md: 'table-cell' } },
    render: (document) => (document.issue_date && fDate(document.issue_date)) || '-',
  },
  {
    id: 'uri',
    label: 'PDF',
    alignRight: false,
    render: (document, t, isDesktop) => (
      <Link
        href={getUriToRedirect(document)}
        target={isDesktop ? "_blank" : "_self"}
        sx={{ color: 'text.secondary', width: '100%' }}
      >
        {isDesktop ? (document.exists && t('document.list.download')) || t('document.list.generate')
          : (document.exists && <Download /> || <NoteAddIcon/>)
          }
      </Link>
    ),
  },
]

type Filter = {
  client_name?: string
  quote_id?: string
  type?: string[]
  exists?: string | null
  date_start?: string
  date_end?: string
}

export default function DocumentList() {
  const { translate: t } = useLocales()

  const isDesktop = useResponsive('up', 'lg')

  const [isOpen, setIsOpen] = useState(isDesktop)

  useEffect(() => setIsOpen(!!isDesktop), [isDesktop])

  const handleTop = () => {
    window.scrollTo(0, 0)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true)
  const [isReloading, setIsReloading] = useState(false)

  // Documents
  const [documents, setDocuments] = useState<DocumentPDF[] | []>([])

  // Filters
  const dateEnd = new Date()
  dateEnd.setUTCHours(23, 59, 59, 99)
  const dateStart = new Date()
  dateStart.setMonth(dateEnd.getMonth() - 1)
  dateStart.setUTCHours(0, 0, 0, 0)

  const defaultFilters = {
    date_end: dateEnd.toISOString(),
    date_start: dateStart.toISOString(),
  }

  const [filter, setFilter] = useState<Filter>(defaultFilters)
  const [order, setOrder] = useState<string[]>(['quote_id', 'DESC'])
  const [range, setRange] = useState<number[]>([0, 25])

  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [downloadDisable, setDownloadDisable] = useState(false)

  const handleChangePage = (_event, newPage) => {
    setRange([newPage * rowsPerPage, (newPage + 1) * rowsPerPage])
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
    setRange([0, newRowsPerPage])
  }

  const handleRequestSort = (_event, property) => {
    const direction = order[0] !== property ? 'DESC' : order[1] === 'DESC' ? 'ASC' : 'DESC'
    setOrder([property, direction])
  }

  const handleFilter = (event) => {
    setFilter((filter) => {
      return { ...filter, [event.target.name]: event.target.value }
    })
  }

  const clearFilter = () => {
    setFilter(defaultFilters)
    setIsReloading(true)
    setIsOpen(!!isDesktop)
  }

  const sendRequest = useCallback(() => {
    setIsLoading(true)
    setIsOpen(!!isDesktop)
    handleTop()
    axiosInstance
      .get<{ result: DocumentPDF[]; total: number }>(`api/pdf`, {
        params: {
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
          range: JSON.stringify(range),
        },
      })
      .then((response) => {
        setDocuments(response.data.result)
        setTotal(response.data.total)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching documents and quotes:', error)
        setIsLoading(false)
      })
  }, [range, order, filter, isDesktop])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => sendRequest(), [range, order])

  useEffect(() => {
    if (isReloading) {
      sendRequest()
      setIsReloading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloading])

  const downloadPDFZip = async (documentToZip) => {
    const documentsUris = documentToZip.map((document) => {
      const documentUri = document.uri
      const pdf_uri = getPathDocument(documentUri)
      return pdf_uri
    })
    try {
      const response = await axiosInstance.post(
        '/api/pdf/zip',
        { uris: documentsUris },
        { responseType: 'blob' }
      )
      const zipBlob = new Blob([response.data], { type: 'application/zip' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(zipBlob)
      link.download = 'documents.zip'
      link.click()
    } catch (error) {
      console.error('Error downloading zip file')
    }
  }

  useEffect(() => {
    setDownloadDisable(!documents.map((i) => i.exists).some(Boolean) || documents.length == 0)
  }, [documents])

  return (
    <>
      <Page title={t('document.title')}>
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={isDesktop ? 5 : 0}
          >
            <Typography variant="h4">{t('document.title')}</Typography>
            {!isDesktop && (
              <Button
                onClick={() => setIsOpen(!isOpen)}
                aria-label="expand"
                size="small"
                variant={isOpen ? 'contained' : 'text'}
              >
                <TuneIcon />
              </Button>
            )}
            <Tooltip title={t('document.list.download_list_helper') || ''}>
              <Button
                sx={{ ml: 2 }}
                disabled={downloadDisable}
                variant="outlined"
                onClick={() => downloadPDFZip(documents)}
              >
                <Download />
              </Button>
            </Tooltip>
          </Stack>
          <Stack direction={{ xs: 'column', lg: 'row' }} alignItems="flex-start" spacing={2}>
            <Collapse in={isOpen} timeout="auto" sx={{ width: { xs: '100%', lg: '25%' } }}>
              <Card style={{ margin: '0', width: '100%' }}>
                <CardHeader title={t('document.list.filters')} variant="h6"></CardHeader>
                <Stack direction="column" justifyContent="start" spacing={2} p={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      label={t('document.list.date_start')}
                      value={filter.date_start}
                      onChange={(date: Date | null) => {
                        let formatedDate: Date | string | null = date
                        if (formatedDate) {
                          formatedDate.setUTCHours(0, 0, 0, 0)
                          formatedDate = formatedDate.toISOString()
                        }
                        handleFilter({
                          target: {
                            name: 'date_start',
                            value: formatedDate,
                          },
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="date_start"
                          value={filter.date_start}
                          fullWidth
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      label={t('document.list.date_end')}
                      value={filter.date_end}
                      minDate={filter.date_start ? new Date(filter.date_start) : undefined}
                      onChange={(date: Date | null) => {
                        let formatedDate: Date | string | null = date
                        if (formatedDate) {
                          formatedDate.setUTCHours(23, 59, 59, 99)
                          formatedDate = formatedDate.toISOString()
                        }
                        handleFilter({
                          target: {
                            name: 'date_end',
                            value: formatedDate,
                          },
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="date_end"
                          value={filter.date_end}
                          fullWidth
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="filter-name"
                    label={t('document.list.filter_by_name')}
                    name="client_name"
                    value={filter.client_name || ''}
                    onChange={handleFilter}
                    variant="outlined"
                    margin="normal"
                  />
                  <TextField
                    id="filter-name"
                    label={t('document.list.filter_by_id')}
                    value={filter.quote_id || ''}
                    name="quote_id"
                    onChange={handleFilter}
                    variant="outlined"
                    margin="normal"
                  />

                  <FormControl sx={{ ml: 2 }}>
                    <InputLabel>{t('document.list.type')}</InputLabel>
                    <Select
                      sx={{ pl: 2 }}
                      label={t('document.list.type')}
                      multiple
                      name="type"
                      value={filter.type || []}
                      onChange={handleFilter}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value: string) => (
                            <Chip key={value} label={t(`document.list.${value}`)} />
                          ))}
                        </Box>
                      )}
                    >
                      {documentTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {t(`document.pdf.${type}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="exists">{t('document.list.exists.label')}</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={filter.exists || 'all'}
                      name="exists"
                      onChange={handleFilter}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label={t('document.list.exists.all', 'All') as string}
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={t('document.list.exists.yes', 'Yes') as string}
                      />
                      <FormControlLabel
                        value="not_yet"
                        control={<Radio />}
                        label={t('document.list.exists.not_yet', 'Not yet') as string}
                      />
                    </RadioGroup>
                  </FormControl>
                  <Button variant="outlined" startIcon={<Search />} onClick={sendRequest}>
                    {t('document.list.filter')}
                  </Button>
                  <Button variant="outlined" startIcon={<Clear />} onClick={clearFilter}>
                    {t('document.list.reset')}
                  </Button>
                </Stack>
              </Card>
            </Collapse>
            <Card style={{ width: '100%' }}>
              {!isLoading && (
                <>
                  <Scrollbar>
                    <TableContainer>
                      <Table>
                        <DataListHead
                          align="center"
                          order={order[1]}
                          orderBy={order[0]}
                          headLabel={TABLE_HEAD}
                          rowCount={total}
                          numSelected={rowsPerPage}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {documents &&
                            documents.map((document) => {
                              const key = document.id + document.type
                              return (
                                <TableRow key={key} tabIndex={-1} role="checkbox">
                                  {TABLE_HEAD.map((head) => (
                                    <TableCell
                                      align="center"
                                      sx={head.sx}
                                      key={`${key}-${head.id}`}
                                    >
                                      {head.render && head.render(document, t, isDesktop)}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: t('document.list.all'), value: -1 }]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </Card>
          </Stack>
        </Container>
      </Page>
    </>
  )
}
