import { createContext } from "react"
import useProfileIsComplete from "../hooks/useProfileIsComplete"

export const ConfigurationContext = createContext(null)

export function ConfigurationProvider({ children }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isComplete, loading, company] = useProfileIsComplete()

  if (loading) {
    return <></>
  }

  return (
    <ConfigurationContext.Provider value={company}>
      {children}
    </ConfigurationContext.Provider>
  )
}