import { saveAs } from 'file-saver'
import { QuoteExtended } from '../@types/quote'
import { getBalanceBillPDFURI, getDepositBillPDFURI, getQuotePDFURI } from './getPDFInformations'
import { PATH_QUOTE } from '../routes/paths'

export default function downloadFile(uri, fileName) {
  return () => {
    saveAs(uri, fileName)
  }
}

export const downloadPDF = (quoteId, lang: string, document_type: string) => {
  let pdf
  switch (document_type) {
    case 'quote':
      pdf = downloadQuotePDF(quoteId, lang)
      break
    case 'deposit':
      pdf = downloadDepositBillPDF(quoteId, lang)
      break
    case 'balance':
      pdf = downloadBalanceBillPDF(quoteId, lang)
      break
    default:
      throw new Error(`Unsupported document type: ${document_type}`)
  }
  return pdf
}

export const downloadQuotePDF = (quoteId, lang: string) => {
  const uri = getQuotePDFURI(quoteId, lang)
  return uri
}

export const downloadDepositBillPDF = (quoteId, lang: string) => {
  const uri = getDepositBillPDFURI(quoteId, lang)
  return uri
}

export const downloadBalanceBillPDF = (quoteId, lang: string) => {
  const uri = getBalanceBillPDFURI(quoteId, lang)
  return uri
}

export const downloadVolumeCalculatorSheet = (
  quote: QuoteExtended,
  lang: string,
  model: string
) => {
  const uri = `${process.env.REACT_APP_SERVER_URL}/api/sheets/${lang}/${model}/${quote.volume_calculator_sheet}.pdf`
  return uri
}

export const getUriToRedirect = (document: any) => {
  if (document.redirect) {
    switch (document.type) {
      case 'deposit_bill':
        return `${PATH_QUOTE.view.root}/${document.quote_uuid}?document=deposit`
      case 'balance_bill':
        return `${PATH_QUOTE.view.root}/${document.quote_uuid}?document=balance`
      default: return ''
    }
  } 
  return `${process.env.REACT_APP_SERVER_URL}${document.uri}`
}
