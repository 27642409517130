import Alert from '@mui/material/Alert'

import axiosInstance from '../utils/axios'
import useFetch from './useFetch'
import useLocales from './useLocales'
import { Box, Stack } from '@mui/material'
const useApiHealth = (): { loading: boolean; renderHealth: any } => {
  const { error, loading } = useFetch(axiosInstance.get, [`health`])
  const { translate: t } = useLocales()

  const renderHealth = () => {
    if (!error) {
      return ''
    }
    return (
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Alert severity="error">{t('services_unavailable')}</Alert>
        </Box>
      </Stack>
    )
  }

  return { loading, renderHealth }
}

export default useApiHealth
