import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
// material
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import useLocales from '../hooks/useLocales'

// ----------------------------------------------------------------------

DataListHead.propTypes = {
  align: PropTypes.string,
  order: PropTypes.oneOf(['ASC', 'DESC']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
}

export default function DataListHead({ align, order, orderBy, headLabel, onRequestSort }) {
  const { translate: t } = useLocales()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            sx={headCell.sx || undefined}
            key={headCell.id}
            align={align ? align : headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order.toLowerCase() : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={(orderBy === headCell.id ? order : 'ASC').toLowerCase()}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
